import React from "react"

import Layout from "src/components/layout"

const NotFoundPage = () => (
  <Layout>
    <h1>404 NOT FOUND</h1>
  </Layout>
)

export default NotFoundPage
